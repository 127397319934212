const musicModalContent = `  <iframe
    style="border-radius:12px"
    src="https://open.spotify.com/embed/playlist/7wWyVi7EejH3cIvSuWbsKM?utm_source=generator"
    width="100%"
    height="352"
    frameBorder="0"
    allowfullscreen=""
    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
    loading="lazy"
  ></iframe>`;

const canModalContent = `
    <h3 class="modalSub-Heading3">Support me by buying me a Coffee!</h3>
      <div class="center coffee">
        <a href="https://www.buymeacoffee.com/dhruvdange" target="_blank"
          ><img
            src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
            alt="Buy Me A Coffee"
            style="height: 60px !important; width: 217px !important"
        /></a>
      </div>
`;

const toddlertContent = `
<h1 class="modalSub-Heading3">Toddler</h1>
<ul class="projectSub">
<li>Toddlert is a mobile app that enables people to identify and help missing children using facial recognition.</li><br>
<li>The project idea, given by the Cyber Crime Unit, Special Cell, Delhi Police, MHA, was selected for the Smart India Hackathon 2022 finale.</li><br>
<li>The app uses facial recognition to identify missing children from a database and provides several features to ensure child safety and user credibility.</li><br>
</ul>

<a href="https://github.com/DhruvDange" target="_blank"><button type="button" class="button-8">GitHub Repo</button></a>
`;

const ppmlContent = `
<h1 class="modalSub-Heading3">Privacy preserving machine learning using zkSNARKs</h1>
<ul class="projectSub">
<li>Used zkSNARKs to create proof of valid machine learning computations, and valid data and models</li><br>
<li>Used groth16 proving system.</li><br>
<li>Acts as an additional scalability layer for homomorphic machine learning.</li><br>
</ul>

<a href="https://www.unisys.com/news-release/unisys-innovation-program-announces-this-years-winners/" target="_blank"><button type="button" class="button-8">Article Link</button></a>
`;

const ourVisionContent = `
<h1 class="modalSub-Heading3">OurVision - An Assistive Device for the Visually Impaired</h1>
<ul class="projectSub">
<li>Designed and developed hardware glasses that help the visually impaired with day to day activities with 100% adoption among users.
</li><br>
<li>Device performs real-time object detection, text recognition and translation to local dialects.
</li><br>
<li>Deloped the glasses at a cost 90% lower than similar counterparts.</li><br>
</ul>
<a href="hhttps://epics.ieee.org/project/design-and-development-of-head-mounted-assistive-device-for-the-blind-using-computer-vision/" target="_blank"><button type="button" class="button-8">Article Link</button></a>

`;

const cosmicPersuitContent = `
<h1 class="modalSub-Heading3">Cosmic Pursuit</h1>
<ul class="projectSub">
<li>Cosmic Pursuit was an on-campus treasure hunt organized by RIT S.T.A.R.D.U.ST on 10th Jun, 2022. The event witnessed around 150 participants in the form of 50+ teams
</li><br>
<li>Created the platform for the treasure hunt along with a live leaderboard in order to make the hunt more interactive and engaging.
</li><br>
</ul>
<a href="https://github.com/psk907/cosmic-pursuit" target="_blank"><button type="button" class="button-8">GitHub Repo</button></a>

`;

const discordBotContent = `
<h1 class="modalSub-Heading3">Discord Bot</h1>
<ul class="projectSub">
<li>Built a fully functional discord bot to manage a server, play audios and display jokes from the internet using web scraping.
</li><br>
<li>Created the platform for the treasure hunt along with a live leaderboard in order to make the hunt more interactive and engaging.
</li><br>
</ul>
<a href="https://github.com/DhruvDange/Dhaba-bot" target="_blank"><button type="button" class="button-8">GitHub Repo</button></a>

`;

export { musicModalContent, canModalContent, toddlertContent, ppmlContent, ourVisionContent, cosmicPersuitContent, discordBotContent};
